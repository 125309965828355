footer {
	background: $colors-background-3;
	width: 100%;
	padding: 60px 0 30px;
	.logo {
		height: 160px;
		display: block;
		margin: 0 auto 20px;
	}
	ul.nav {
		list-style: none;
		display: block;
		padding: 0;
		margin: 0 auto 10px;
		width: 100%;
		text-align: center;
		li {
			display: inline-block;
			padding: 0;
			margin: 0;
			margin-right: 10px;
			position: relative;
			@media screen and (max-width: $md-breakpoint) {
				margin-right: 0;
				display: block;
				margin-bottom: 10px;
				&:after {
					display: none;
				}
			}
			&:after {
				@include position(absolute, $right: -5px, $top: 3.5px);
				@include font-size(12px);
				font-weight: 300;
				color: $colors-foreground-4;
				content: '|';
			}
			&:last-of-type {
				margin-right: 0;
				margin-bottom: 0;
				&:after {
					display: none;
				}
			}
			a {
				@include font-size(13px);
				@include transition(all $transition-time $transition-curve);
				font-family: $primary-font-stack;
				font-weight: 300;
				padding: 20px 10px;
				color: $colors-foreground-2;
				text-decoration: none;
				@include on-event(false) {
					@include opacity(0.6);
				}
			}
		}
	}
	p {
		@include font-size(13px);
		font-weight: 300;
		color: lighten($colors-foreground-4, 20%);
		text-align: center;
		margin: 0;
	}
}
