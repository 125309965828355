header {
	@include position(fixed, $top: 0, $left: 0);
	@include transition(all $transition-time $transition-curve);
	z-index: 2;
	padding: 20px 0 0;
	background: $colors-background-1;
	width: 100%;
	.logo-container {
		@include transition(all $transition-time $transition-curve);
		display: block;
		width: 120px;
		margin: 0 auto 10px;
		.logo {
			width: 100%;
			display: block;
			cursor: pointer;
		}
		@include on-event(false) {
			@include opacity(0.75);
		}
	}
	ul.nav {
		list-style: none;
		display: block;
		padding: 0;
		margin: 0 auto;
		width: 100%;
		text-align: center;
		li {
			cursor: pointer;
			display: inline-block;
			padding: 0;
			margin: 0;
			&:last-of-type {
				margin-right: 0;
			}
			a {
				@include font-size(14px);
				@include transition(all $transition-time $transition-curve);
				font-family: $secondary-font-stack;
				cursor: pointer;
				font-weight: 600;
				display: block;
				padding: 20px 10px;
				color: $colors-foreground-1;
				text-transform: uppercase;
				text-decoration: none;
				letter-spacing: 0.4px;
				@include on-event(false) {
					@include opacity(0.75);
				}
			}
		}
	}
	&.active {
		@include box-shadow(0 2px 25px rgba($colors-foreground-4, 0.15));
		padding: 10px 0 0;
		.logo-container {
			width: 50px;
			margin-bottom: 0px;
		}
		ul.nav:not(.mobile-nav) {
			margin-bottom: 10px;
			li a {
				padding: 10px;
			}
		}
	}
	.mobile-nav-container {
		@include transition(all $transition-time $transition-curve);
		@include position(fixed, $top: 52px, $right: -768px);
		padding: 10px 120px 0 0;
		background: $colors-background-1;
		width: 100%;
		&.expand {
			right: 0;
		}
		.mobile-nav {
			text-align: left;
			li {
				display: block;
				a {
					@include font-size(18px);
					padding-left: 40px;
				}
			}
		}
	}
}

#root {
	padding-top: 206px;
	@media screen and (max-width: $md-breakpoint) {
		padding-top: 53px;
	}
}

@media screen and (max-width: $md-breakpoint) {
	header,
	header.active {
		padding: 5px 0 0;
		.logo-container {
			float: left;
			width: 43px;
			margin-bottom: 5px;
		}
		.hamburger {
			@include transform(scale(0.4));
			float: right;
			margin-right: -15px;
			button {
				padding: 10px;
			}
		}
	}
}
