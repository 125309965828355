.side-photo {
	@include size(100%);
	display: inline-block;
	&.hoa {
		background: url('../../images/hoa-photo.jpg') no-repeat center center;
		background-size: cover;
		@media screen and (-webkit-min-device-pixel-ratio: 2) {
			background: url('../../images/hoa-photo-x2.jpg') no-repeat center center;
			background-size: cover;
		}
	}
	&.providers {
		background: url('../../images/providers-photo.jpg') no-repeat center center;
		background-size: cover;
		@media screen and (-webkit-min-device-pixel-ratio: 2) {
			background: url('../../images/providers-photo-x2.jpg') no-repeat center
				center;
			background-size: cover;
		}
	}
	&.contact {
		background: url('../../images/contact-photo.jpg') no-repeat center center;
		min-height: 400px;
		@media screen and (max-width: $lg-breakpoint) {
			margin-bottom: 20px;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) {
			background: url('../../images/contact-photo-x2.jpg') no-repeat center
				center;
		}
	}
	@media screen and (max-width: $lg-breakpoint) {
		height: 400px;
	}
}

p.side-photo-text {
	@include font-size(14px);
	margin-bottom: 0;
	@media screen and (max-width: $lg-breakpoint) {
		margin-bottom: 20px;
	}
}
