h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
div,
span,
i,
b,
strong,
em {
	&.text-center {
		text-align: center;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
	margin: 0 0 10px;
	position: relative;
	font-family: $secondary-font-stack;
	color: $colors-foreground-2;
	small {
		font-size: 70%;
	}
	a {
		color: inherit;
	}
}

h1 {
	@include font-size(48px);
	@media screen and (max-width: $md-breakpoint) {
		@include font-size(42px);
	}
}

h2 {
	@include font-size(42px);
	@media screen and (max-width: $md-breakpoint) {
		@include font-size(32px);
	}
}

h3 {
	@include font-size(36px);
	@media screen and (max-width: $md-breakpoint) {
		@include font-size(24px);
	}
}

h4 {
	@include font-size(32px);
	font-weight: 600;
	@media screen and (max-width: $md-breakpoint) {
		@include font-size(24px);
	}
	@media screen and (max-width: $sm-breakpoint) {
		@include font-size(20px);
	}
}

h5 {
	@include font-size(24px);
	font-weight: 600;
}

h6 {
	@include font-size(18px);
	font-weight: 600;
}

p {
	@include font-size(16px);
	font-family: $primary-font-stack;
	color: $colors-foreground-3;
	font-weight: 300;
	margin: 0 0 20px;
	line-height: 28px;
	letter-spacing: 0.25px;
	&.small {
		@include font-size(14px);
		line-height: initial;
		margin: 0 0 10px;
	}
}

a {
	@include transition(all $transition-time $transition-curve);
	text-decoration: none;
	font-size: inherit;
	cursor: pointer;
	&.disabled {
		cursor: not-allowed;
		@include opacity(0.65);
	}
}
