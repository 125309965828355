// main: ../main.scss

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
    @if $self {
        &,
        &:active,
        &:focus,
        &:hover {
            @content;
        }
    } @else {
        &:active,
        &:focus,
        &:hover {
            @content;
        }
    }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}

/// Responsive manager
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
/// @link http://sass-guidelin.es/#breakpoint-manager Sass Guidelines - Breakpoint Manager
@mixin respond-to($breakpoint) {
    $query: map-get($breakpoints, $breakpoint);

    @if not $query {
        @error "No value found for `#{$breakpoint}`. Please make sure it is defined in `$breakpoints` map.";
    }

    @media #{if(type-of($query) == "string", unquote($query), inspect($query))} {
        @content;
    }
}

/// Font Size
@function calculateRem($size) {
    $remSize: $size / 16px;

    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

/// Line Height
@mixin line-height($heightValue: 12) {
    line-height: $heightValue + px;

    //fallback for old browsers
    line-height: 0.125 * $heightValue + rem;
}

/// Keyframes
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

/// Animation
@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

/// Transitions
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin transition-transform($transition...) {
    -webkit-transition: -webkit-transform $transition;
    -moz-transition: -moz-transform $transition;
    -o-transition: -o-transform $transition;
    transition: transform $transition;
}

@mixin transition-duration($duration...) {
    -moz-transition-property: $duration;
    -o-transition-property: $duration;
    -webkit-transition-property: $duration;
    transition-property: $duration;
}

/// Cross browser opacity
@mixin opacity($opacity) {
    opacity: $opacity;

    $opacity-ie: $opacity * 100;

    filter: alpha(opacity = $opacity-ie);

    //IE8
}

@mixin filter($filter...) {
    -webkit-filter: $filter;
    filter: $filter;
}

/// Size
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

/// Positioning
@mixin position(
    $position,
    $top: null,
    $right: null,
    $bottom: null,
    $left: null
) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

/// Border Radius
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;

    /* stops bg color from leaking outside the border: */
}

@mixin border-top-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    background-clip: padding-box;
}

@mixin border-bottom-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-top-left-radius($radius) {
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-top-right-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    background-clip: padding-box;
}

@mixin border-bottom-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-bottom-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    background-clip: padding-box;
}

/// Truncate Text
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/// Box Shadow
@mixin box-shadow($styles...) {
    -webkit-box-shadow: $styles;
    -moz-box-shadow: $styles;
    box-shadow: $styles;
}

/// Appearance
@mixin appearance($styles...) {
    -webkit-appearance: $styles;
    -moz-appearance: $styles;
    appearance: $styles;
}

/// Box Sizing
@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;

    // Safari <= 5
    -moz-box-sizing: $box-model;

    // Firefox <= 19
    box-sizing: $box-model;
}

// generic transform
@mixin transform($transforms...) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// rotate
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

@mixin linear-gradient($direction, $color-stops...) {
    // Direction has been omitted and happens to be a color-stop
    @if is-direction($direction) == false {
        $color-stops: $direction, $color-stops;
        $direction: 180deg;
    }

    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(
        legacy-direction($direction),
        $color-stops
    );
    background: linear-gradient($direction, $color-stops);
}

@mixin radial-gradient($from, $to) {
    background: -moz-radial-gradient($from 0%, $to 100%);
    background: -webkit-radial-gradient($from 0%, $to 100%);
    background: -o-radial-gradient($from 0%, $to 100%);
    background: -ms-radial-gradient($from 0%, $to 100%);
    background: radial-gradient($from 0%, $to 100%);
    background-color: $from;
}

/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
    $is-keyword: index(
        (
            to top,
            to top right,
            to right top,
            to right,
            to bottom right,
            to right bottom,
            to bottom,
            to bottom left,
            to left bottom,
            to left,
            to left top,
            to top left
        ),
        $value
    );
    $is-angle: type-of($value) == "number" and
        index("deg" "grad" "turn" "rad", unit($value));

    @return $is-keyword or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
    @if is-direction($value) == false {
        @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
    }

    $conversion-map: (
        to top: bottom,
        to top right: bottom left,
        to right top: left bottom,
        to right: left,
        to bottom right: top left,
        to right bottom: left top,
        to bottom: top,
        to bottom left: top right,
        to left bottom: right top,
        to left: right,
        to left top: right bottom,
        to top left: bottom right
    );

    @if map-has-key($conversion-map, $value) {
        @return map-get($conversion-map, $value);
    }

    @return 90deg - $value;
}

@mixin text-shadow($horizOffset, $vertOffset, $blur, $color) {
    -webkit-text-shadow: $horizOffset $vertOffset $blur $color;
    -moz-text-shadow: $horizOffset $vertOffset $blur $color;
    -ms-text-shadow: $horizOffset $vertOffset $blur $color;

    /* IE 10+ */

    -o-text-shadow: $horizOffset $vertOffset $blur $color;
    text-shadow: $horizOffset $vertOffset $blur $color;
}

@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin stroke($width, $color) {
    $width: $width + 0;

    $shadow: 0 0 0 transparent;

    $i: 0;

    $w: 1;

    @while $i < $width {
        $i: $i + 1;

        $j: 0;

        $w: $w + 2;

        @for $r from 1 through $w {
            @for $c from 1 through $w {
                $x: $c - ceil($w / 2);

                $y: $r - ceil($w / 2);

                $shadow: #{$shadow}, #{$x}px #{$y}px 0 $color;
            }
        }
    }

    text-shadow: $shadow;
}

// String Replace
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(
                str-slice($string, $index + str-length($search)),
                $search,
                $replace
            );
    }

    @return $string;
}

// Font Face
@mixin font-face(
    $name,
    $path,
    $weight: null,
    $style: null,
    $exts: eot woff2 woff ttf svg
) {
    $src: null;

    $extmods: (
        eot: "?#iefix",
        svg: "#" + str-replace($name, " ", "_"),
    );

    $formats: (
        otf: "opentype",
        ttf: "truetype",
    );

    @each $ext in $exts {
        $extmod: if(
            map-has-key($extmods, $ext),
            $ext + map-get($extmods, $ext),
            $ext
        );

        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);

        $src: append(
            $src,
            url(quote($path + "." + $extmod)) format(quote($format)),
            comma
        );
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

@mixin clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

// Flexbox Inline display
@mixin inline-flex() {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
    -webkit-flex-flow: $flow;
    -moz-flex-flow: $flow;
    -ms-flex-flow: $flow;
    flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
    -webkit-flex-grow: $grow;
    -moz-flex-grow: $grow;
    -ms-flex-grow: $grow;
    flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
    -webkit-flex-shrink: $shrink;
    -moz-flex-shrink: $shrink;
    -ms-flex-shrink: $shrink;
    flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
    -webkit-flex-basis: $width;
    -moz-flex-basis: $width;
    -ms-flex-basis: $width;
    flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
    -webkit-align-content: $align;
    -moz-align-content: $align;
    -ms-align-content: $align;
    align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
    -ms-flex-align: $align;
    align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
    -webkit-align-self: $align;
    -moz-align-self: $align;
    -ms-align-self: $align;
    align-self: $align;
}

@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin caret-down() {
    border-top: $caret-width solid;
    border-right: $caret-width solid transparent;
    border-bottom: 0;
    border-left: $caret-width solid transparent;
}

@mixin caret-up() {
    border-top: 0;
    border-right: $caret-width solid transparent;
    border-bottom: $caret-width solid;
    border-left: $caret-width solid transparent;
}

@mixin caret-right() {
    border-top: $caret-width solid transparent;
    border-right: 0;
    border-bottom: $caret-width solid transparent;
    border-left: $caret-width solid;
}

@mixin caret-left() {
    border-top: $caret-width solid transparent;
    border-right: $caret-width solid;
    border-bottom: $caret-width solid transparent;
}

@mixin caret($direction: down) {
    @if $enable-caret {
        &::after {
            display: inline-block;
            margin-left: $caret-spacing;
            vertical-align: $caret-vertical-align;
            content: "";
            @if $direction == down {
                @include caret-down();
            } @else if $direction == up {
                @include caret-up();
            } @else if $direction == right {
                @include caret-right();
            }
        }

        @if $direction == left {
            &::after {
                display: none;
            }

            &::before {
                display: inline-block;
                margin-right: $caret-spacing;
                vertical-align: $caret-vertical-align;
                content: "";
                @include caret-left();
            }
        }

        &:empty::after {
            margin-left: 0;
        }
    }
}

@mixin reset-text() {
    font-family: $font-family-base;
    // We deliberately do NOT reset font-size or word-wrap.
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: $line-height-base;
    text-align: left; // Fallback for where `start` is not supported
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
}
