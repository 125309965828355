.form-container {
	@include transition(all $transition-time linear);
	position: relative;
	.inner-container {
		white-space: normal;
		width: 100%;
		display: inline-block;
		&.nopadding {
			padding: 0;
		}
	}
	&.success {
		.inner-container {
			border: 1px solid rgba(lighten($colors-foreground-4, 15%), 0.4);
			padding: 20px;
		}
	}
}

.form-row {
	@include transition(all $transition-time ease);
	@include opacity(1);
	position: relative;
	&.disabled {
		@include opacity(0.6);
		* {
			cursor: not-allowed;
		}
		cursor: not-allowed;
	}
	&.error,
	&.invalid {
		.form-control,
		.service-plan-option {
			border-color: $colors-red;
			&:focus {
				@include box-shadow(0 0 5px $colors-red);
			}
		}
		.form-response,
		.helper {
			color: $colors-red;
		}
	}
	display: block;
	&:not(:last-of-type) {
		margin-bottom: 20px;
	}
	.form-response {
		@include transition(all $transition-time ease);
		@include font-size(12px);
		font-weight: 300;
		margin-top: 5px;
		font-family: $primary-font-stack;
		&.error,
		&.invalid,
		&.red {
			color: $colors-red;
		}
	}
	&.floating-input {
		&.error,
		&.invalid {
			.form-control + label.float-label {
				color: $colors-red;
			}
		}
		.form-control {
			+ label.float-label {
				@include transition(opacity $transition-time ease, top 0.2s linear);
				@include font-size(8px);
				@include position(absolute, $top: 10px, $left: 10px);
				@include opacity(0);
				font-family: $primary-font-stack;
				font-weight: 300;
				color: $colors-foreground-3;
			}
			&:focus {
				+ label.float-label {
					color: $colors-blue;
				}
			}
			&:not(.input-empty) {
				+ label.float-label {
					@include opacity(1);
					top: 2px;
				}
			}
		}
		textarea.form-control {
			padding-top: 13px;
		}
	}
}

.form-control {
	@include appearance(none);
	@include transition(all $transition-time ease);
	@include font-size(12px);
	position: relative;
	font-family: $primary-font-stack;
	font-weight: 400;
	border: 1px solid rgba(lighten($colors-foreground-4, 15%), 0.4);
	background: lighten($colors-background-1, 1%);
	display: inline-block;
	height: 36px;
	padding-left: 10px;
	color: $colors-foreground-2;
	@include on-event(true) {
		outline: none;
	}
	&:focus {
		@include box-shadow(0 0 5px $colors-blue);
	}
	&.block {
		width: 100%;
	}
}

textarea.form-control {
	height: 96px;
}

form .row .form-row {
	&:last-of-type {
		margin-bottom: 10px;
	}
	margin-bottom: 10px;
}

::-webkit-input-placeholder {
	color: rgba($colors-foreground-3, 0.6);
	font-family: $primary-font-stack;
	font-weight: 300;
}
::-moz-placeholder {
	color: rgba($colors-foreground-3, 0.6);
	font-family: $primary-font-stack;
	font-weight: 300;
}
:-ms-input-placeholder {
	color: rgba($colors-foreground-3, 0.6);
	font-family: $primary-font-stack;
	font-weight: 300;
}
:-moz-placeholder {
	color: rgba($colors-foreground-3, 0.6);
	font-family: $primary-font-stack;
	font-weight: 300;
}
