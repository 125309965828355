html,
body {
	box-sizing: border-box;
	font: $base-font-size $primary-font-stack;
	font-weight: 400;
	position: absolute;
	width: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
	background: $colors-background-1;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	-webkit-overflow-scrolling: touch;
}

@media (max-width: $xl-breakpoint) {
	.hide-mobile {
		display: none !important;
	}
}

@media (min-width: $xl-breakpoint) {
	.hide-desktop {
		display: none !important;
	}
}

.row {
	margin-bottom: 20px;
}

.no-margin-bottom {
	margin-bottom: 0;
}

.margin-bottom-sm {
	@media (max-width: $lg-breakpoint) {
		margin-bottom: 20px;
	}
}

.margin-bottom-md {
	@media screen and (min-width: $md-breakpoint) {
		margin-bottom: 40px;
	}
}

.lg-margin {
	margin: 60px 0;
}

.margin-bottom-lg {
	margin-bottom: 60px;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.container {
	@media screen and (min-width: $xl-breakpoint) {
		max-width: 1400px;
	}
}
