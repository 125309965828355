.hero {
	@include size(100%, 500px);
	margin: 0;
	padding: 0;
	position: relative;
	&.home-hero {
		background: url('../../images/home-hero.jpg') no-repeat center center;
		overflow: hidden;
		background-size: cover;
		@media screen and (-webkit-min-device-pixel-ratio: 2) {
			background: url('../../images/home-hero-x2.jpg') no-repeat center center;
			background-size: cover;
		}
		@media screen and (max-width: $xl-breakpoint) {
			background: url('../../images/home-hero-md.jpg') no-repeat center center;
			background-size: cover;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $xl-breakpoint) {
			background: url('../../images/home-hero-md-x2.jpg') no-repeat center
				center;
			background-size: cover;
		}
		@media screen and (max-width: $md-breakpoint) {
			background: url('../../images/home-hero-sm.jpg') no-repeat center center;
			background-size: cover;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $md-breakpoint) {
			background: url('../../images/home-hero-sm-x2.jpg') no-repeat center
				center;
			background-size: cover;
		}
		&:after {
			@include size(1400px, 60px);
			@include position(absolute, $bottom: 0, $left: 50%);
			background: url('../../images/curve-graphic.png') no-repeat bottom center;
			background-size: 100% auto;
			margin-left: -700px;
			margin-bottom: -1px;
			display: block;
			content: '';
			@media screen and (max-width: $xl-breakpoint) {
				@include size(960px, 50px);
				margin-left: -480px;
			}
			@media screen and (max-width: $md-breakpoint) {
				@include size(768px, 50px);
				margin-left: -384px;
			}
		}
	}
	@media screen and (max-width: $md-breakpoint) {
		height: 400px;
	}
	&.hoa-hero {
		background: url('../../images/hoa-hero.jpg') no-repeat center center;
		background-size: cover;
		@media screen and (-webkit-min-device-pixel-ratio: 2) {
			background: url('../../images/hoa-hero-x2.jpg') no-repeat center center;
			background-size: cover;
		}
		@media screen and (max-width: $xl-breakpoint) {
			background: url('../../images/hoa-hero-md.jpg') no-repeat center center;
			background-size: cover;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $xl-breakpoint) {
			background: url('../../images/hoa-hero-md-x2.jpg') no-repeat center center;
			background-size: cover;
		}
		@media screen and (max-width: $md-breakpoint) {
			background: url('../../images/hoa-hero-sm.jpg') no-repeat center center;
			background-size: cover;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $md-breakpoint) {
			background: url('../../images/hoa-hero-sm-x2.jpg') no-repeat center center;
			background-size: cover;
		}
	}
	&.construction-hero {
		background: url('../../images/construction-hero.jpg') no-repeat center
			center;
		background-size: cover;
		@media screen and (-webkit-min-device-pixel-ratio: 2) {
			background: url('../../images/construction-hero-x2.jpg') no-repeat center
				center;
			background-size: cover;
		}
		@media screen and (max-width: $xl-breakpoint) {
			background: url('../../images/construction-hero-md.jpg') no-repeat center
				center;
			background-size: cover;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $xl-breakpoint) {
			background: url('../../images/construction-hero-md-x2.jpg') no-repeat
				center center;
			background-size: cover;
		}
		@media screen and (max-width: $md-breakpoint) {
			background: url('../../images/construction-hero-sm.jpg') no-repeat center
				center;
			background-size: cover;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $md-breakpoint) {
			background: url('../../images/construction-hero-sm-x2.jpg') no-repeat
				center center;
			background-size: cover;
		}
	}
	&.providers-hero {
		background: url('../../images/providers-hero.jpg') no-repeat center center;
		background-size: cover;
		@media screen and (-webkit-min-device-pixel-ratio: 2) {
			background: url('../../images/providers-hero-x2.jpg') no-repeat center
				center;
			background-size: cover;
		}
		@media screen and (max-width: $xl-breakpoint) {
			background: url('../../images/providers-hero-md.jpg') no-repeat center
				center;
			background-size: cover;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $xl-breakpoint) {
			background: url('../../images/providers-hero-md-x2.jpg') no-repeat center
				center;
			background-size: cover;
		}
		@media screen and (max-width: $md-breakpoint) {
			background: url('../../images/providers-hero-sm.jpg') no-repeat center
				center;
			background-size: cover;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $md-breakpoint) {
			background: url('../../images/providers-hero-sm-x2.jpg') no-repeat center
				center;
			background-size: cover;
		}
	}
	&.hoa-hero,
	&.construction-hero,
	&.providers-hero {
		margin-bottom: 40px;
		@media screen and (max-width: $md-breakpoint) {
			margin-bottom: 0;
		}
	}
}

.secondary-hero {
	width: 100%;
	margin: 0;
	padding: 50px 0;
	box-sizing: border-box;
	&.home-secondary-hero {
		background: url('../../images/home-photo.jpg') no-repeat center center;
		background-size: cover;
		@media screen and (-webkit-min-device-pixel-ratio: 2) {
			background: url('../../images/home-photo-x2.jpg') no-repeat center center;
			background-size: cover;
		}
		@media screen and (max-width: $md-breakpoint) {
			background: url('../../images/home-photo-sm.jpg') no-repeat center center;
			background-size: cover;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $md-breakpoint) {
			background: url('../../images/home-photo-sm-x2.jpg') no-repeat center
				center;
			background-size: cover;
		}
		@media screen and (max-width: $xl-breakpoint) {
			padding-left: 20px;
			padding-right: 20px;
		}
		p {
			@include font-size(14px);
			color: $colors-background-3;
			font-weight: 200;
			line-height: 24px;
			.highlight {
				color: $colors-orange;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	&.contact-secondary-hero {
		padding: 100px 0;
		background: url('../../images/contact-section-background.jpg') no-repeat
			center center;
		background-size: cover;
		@media screen and (-webkit-min-device-pixel-ratio: 2) {
			background: url('../../images/contact-section-background-x2.jpg')
				no-repeat center center;
			background-size: cover;
		}
		@media screen and (max-width: $xl-breakpoint) {
			background: url('../../images/contact-section-background-md.jpg')
				no-repeat center center;
			background-size: cover;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $xl-breakpoint) {
			background: url('../../images/contact-section-background-md-x2.jpg')
				no-repeat center center;
			background-size: cover;
		}
		@media screen and (max-width: $md-breakpoint) {
			background: url('../../images/contact-section-background-sm.jpg')
				no-repeat center center;
			background-size: cover;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: $md-breakpoint) {
			background: url('../../images/contact-section-background-sm-x2.jpg')
				no-repeat center center;
			background-size: cover;
		}
	}
}
