.CookieConsent {
	@include position(fixed, $left: 0, $bottom: 0);
	background: $colors-foreground-2;
	align-items: baseline;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	z-index: 999;
	> div:first-of-type {
		flex: 1 0 300px;
		margin: 15px;
	}
	p {
		@include font-size(12px);
		color: $colors-background-2;
		font-weight: 300;
		b {
			font-weight: 400;
		}
		a {
			@include on-event(true) {
				color: inherit;
				font-weight: 400;
				text-decoration: underline;
			}
		}
	}
	.btn {
		flex: 0 0 auto;
		margin: 15px;
	}
	@media (max-width: 552px) {
		> div {
			width: 100%;
			display: block;
			flex: none;
			padding: 20px;
			&:first-of-type {
				margin: 0;
			}
			&:last-of-type {
				padding-top: 0;
				padding-bottom: 10px;
			}
		}
		.btn {
			display: block;
			flex: none;
			margin: 0 auto;
			max-width: 300px;
			text-align: center;
		}
	}
}
