.btn {
	@include transition(all $transition-time $transition-curve);
	@include font-size(16px);
	height: 48px;
	line-height: 48px;
	text-transform: uppercase;
	background: $colors-foreground-2;
	color: $colors-background-2;
	text-align: center;
	min-width: 140px;
	max-width: 220px;
	font-family: $secondary-font-stack;
	letter-spacing: 1px;
	appearance: none;
	border: none;
	cursor: pointer;
	&.btn-center {
		display: block;
		margin: 0 auto;
	}
	&:not(:disabled) {
		@include on-event(false) {
			background: $colors-foreground-4;
		}
	}
	&:disabled {
		@include opacity(0.6);
		cursor: not-allowed;
	}
}
