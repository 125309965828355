// Typography
$base-font-size: 16px;
$primary-font-stack: 'Roboto', sans-serif;
$secondary-font-stack: 'Raleway', sans-serif;

// Colors
$colors-foreground-1: #000000;
$colors-foreground-2: #101010;
$colors-foreground-3: #212121;
$colors-foreground-4: #484848;

$colors-background-1: #ffffff;
$colors-background-2: #fcfcfc;
$colors-background-3: #f9f9f9;
$colors-background-4: #f0f0f0;

$colors-orange: #ec7a5c;
$colors-blue: #2b63d4;
$colors-green: #25a14a;
$colors-yellow: #e1d316;
$colors-red: #c91a1a;

// Other
$border-radius: 2px;

$transition-time: 0.4s;
$transition-curve: cubic-bezier(0.19, 1, 0.22, 1);

$xl-breakpoint: 1200px;
$lg-breakpoint: 992px;
$md-breakpoint: 768px;
$sm-breakpoint: 576px;
