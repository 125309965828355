.loader {
	&.orange {
		border-top-color: darken($colors-orange, 2%);
		&:before {
			border-top-color: $colors-orange;
		}
		&:after {
			border-top-color: lighten($colors-orange, 2%);
		}
	}
	&.blue {
		border-top-color: darken($colors-blue, 2%);
		&:before {
			border-top-color: $colors-blue;
		}
		&:after {
			border-top-color: lighten($colors-blue, 2%);
		}
	}
	&.white {
		border-top-color: darken($colors-background-4, 2%);
		&:before {
			border-top-color: $colors-background-4;
		}
		&:after {
			border-top-color: darken($colors-background-4, 4%);
		}
	}
	@include position(relative, $left: 50%, $top: 0);
	@include size(100px);
	@include animation(spin 2s linear infinite);
	@include border-radius(50%);
	// @include box-shadow(0 0 5px 0 rgba($colors-foreground-1 0.1));
	display: block;
	margin: 0 0 5px -50px;
	border: 2px solid transparent;
	border-top-color: $colors-blue;
	&:before {
		@include position(
			absolute,
			$top: 3px,
			$left: 3px,
			$right: 3px,
			$bottom: 3px
		);
		@include border-radius(50%);
		@include animation(spin 3s linear infinite);
		content: '';
		border: 2px solid transparent;
		border-top-color: $colors-foreground-3;
	}
	&:after {
		@include position(
			absolute,
			$top: 10px,
			$left: 10px,
			$right: 10px,
			$bottom: 10px
		);
		@include border-radius(50%);
		@include animation(spin 1.5s linear infinite);
		content: '';
		border: 2px solid transparent;
		border-top-color: $colors-orange;
	}
}
@include keyframes(spin) {
	0% {
		@include transform(rotate(0deg));
	}

	100% {
		@include transform(rotate(360deg));
	}
}
